import React, { Component } from "react";
import "./styles.css";
import CustomInput from "../components/CustomInput";
import Button from "../components/Button";
import Cookies from 'universal-cookie';
import $ from 'jquery';

const cookies = new Cookies();
const credentials = {'Mark':'Dagger88','Roy':'Dagger123','YC':'Dagger123','VJ':'6037','CD':'568'}; 

export default class App extends Component {
    checkcredentials = () => {
        if (cookies.get('username2')!==undefined){
            $('.login').css('display','none');
            //alert(cookies.get('username'));
        }
    }

    componentDidMount(){
        this.checkcredentials()
    }
  state = {
    username: "",
    password: ""
  };

  handleChange = e => {
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });

  };

  handleLogin = e => {
    if(credentials[this.state.username]===this.state.password){
        cookies.set('username2',this.state.username,{ maxAge:3153600});
        //alert(cookies.get('username'));
        $('.login').css('display','none');

    }
    else{
        alert('Incorrect Username or Password');
        
    }
    //cookies.set('username',this.state.username,{path: '/'})
    //alert(cookies.get('username'));
    //console.log("Password: " + this.state.password);
};

  render() {
    return (
      <div className="App">
        <form className="form">
          <CustomInput
            labelText="Username"
            id="username"
            formControlProps={{
              fullWidth: true
            }}
            handleChange={this.handleChange}
            type="text"
          />
          <CustomInput
            labelText="Password"
            id="password"
            formControlProps={{
              fullWidth: true
            }}
            handleChange={this.handleChange}
            type="password"
          />

          <Button type="button" color="facebook" className="form__custom-button"  onClick={this.handleLogin}>
            Log in
          </Button>
        </form>
      </div>
    );
  }
}
